import throttle from './base/_throttle.js';
import focusWithin from './base/_focus-within.js';
import './base/_object-fit-cover.js';
import './modules/_masthead.js';

class Scripts {
	constructor() {
		document.addEventListener( 'DOMContentLoaded', this.ready.bind( this ) );
		window.addEventListener( 'resize', throttle( this.resize.bind( this ), 25 ) );
		this.resize();
	}

	ready() {
		focusWithin( 'label, form, fieldset' );
		this.fetchSvgs();
	}

	resize() {
		if ( ! document.body ) {
			return;
		}

		// using this to allow css to know if it should exclude the scroll bar in calc's
		if ( window.innerWidth === document.body.clientWidth ) {
			document.documentElement.classList.remove( 'has-scrollbar' );
		} else {
			document.documentElement.classList.add( 'has-scrollbar' );
		}
	}

	fetchSvgs( selector = '.image-svg-replace', scope = document ) {
		scope.querySelectorAll( selector ).forEach( img => this.fetchSvg( img ) );
	}

	async fetchSvg( img ) {
		try {
			if ( ! img || ! img.src ) {
				throw new Error( 'Invalid svg image tag' );
			}

			const response = await window.fetch( img.src );

			if ( 'image/svg+xml' !== response.headers.get( 'content-type' ) ) {
				throw new Error( 'Response is not svg' );
			}

			const text = await response.text();

			if ( ! text ) {
				throw new Error( 'Empty svg - not replacing' );
			}

			const parser   = new DOMParser();
			const doc      = parser.parseFromString( text, 'image/svg+xml' );
			const scripts  = Array.from( doc.querySelectorAll( 'script' ) ).map( script => script.parentNode.removeChild( script ) );
			const svg      = doc.querySelector( 'svg' );

			[
				'height',
				'width',
				'class',
				'style',
				'className',
			].forEach( attribute => {
				if ( img.getAttribute( attribute ) ) {
					switch ( attribute ) {
						case 'className':
							// merge class names
							svg.classList.add( img.classList );
						break;
						case 'style':
							// merge and overwrite inline styles
							img.getAttribute( 'style' ).split( ';' ).forEach( ( stmt ) => {
								const [ prop, value ] = stmt.split(':');

								if ( prop && value ) {
									svg.style[ prop ] = value;
								}
							} );
						break;
						default:
							// overwrite attributes
							svg.setAttribute( attribute, img.getAttribute( attribute ) );
						break;
					}
				}
			} );

			img.parentNode.insertBefore( svg, img );

			scripts.forEach( script => {
				const svgScript = document.createElement( 'script' );
				svgScript.innerHTML = script.innerHTML.replace( /^<!\[CDATA\[/, '' ).replace( /\]\]>$/, '' );
				img.parentNode.appendChild( svgScript );
			} );

			img.parentNode.removeChild( img );
		} catch ( error ) {
			console.error( error );
		}
	}
}

export default new Scripts;
