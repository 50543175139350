import Headroom from 'headroom.js';
import { enable, disable } from '../base/_tabIndex.js';

class MastheadParent {
	constructor(node, controller) {
		this.node = node;
		this.controller = controller;

		if (!this.node) {
			return;
		}

		// stores the timeout id of the enter hover intent
		this.enterTimeout = null;

		// stores the timeout of the leave hover intent
		this.leaveTimeout = null;

		// is the current item active
		this.active = false;

		// reference the direct child link in case we need to preventDefault on click
		this.link = this.node.querySelector(':scope > .masthead__link');

		// does this item have a back button on the mobile menu
		this.back = this.node.querySelector('.masthead__back');

		// what types of pointer events are 'hoverable'
		this.hoverTypes = ['mouse', 'pen'];

		// trigger the enter hover intent depending on the pointer type
		const onPointerEnter = (e) => {
			if (this.hoverTypes.includes(e.pointerType) && 'hover' === this.controller.getPointerMode()) {
				if (typeof this.leaveTimeout === 'number') {
					window.clearTimeout(this.leaveTimeout);
					this.leaveTimeout = null;
				}

				this.enterTimeout = setTimeout(() => this.activate(), this.controller.hoverDelay);
			}
		};

		// trigger the leave hover intent depending on the pointer type
		const onPointerLeave = (e) => {
			if (this.hoverTypes.includes(e.pointerType) && 'hover' === this.controller.getPointerMode()) {
				if (typeof this.enterTimeout === 'number') {
					window.clearTimeout(this.enterTimeout);
					this.enterTimeout = null;
				}

				this.leaveTimeout = window.setTimeout(() => this.deactivate(), this.controller.hoverDelay);
			}
		};

		// store the last pointer type on pointerup so we can potentially preventDefault onClick
		const onPointerUp = (e) => {
			this.pointerType = e.pointerType ?? null;
		};

		// if not in hover mode, or we're not a hoverable type, simply toggle instead of following the link
		const onClick = (e) => {
			if ('hover' !== this.controller.getPointerMode() || !this.hoverTypes.includes(this.pointerType)) {
				e.preventDefault();
				this.toggle();
			}

			if (this.pointerType) {
				delete this.pointerType;
			}
		};

		// when a element outside of this node is focused, close this menu
		const onFocus = () => {
			let target = document.activeElement;

			while (target && target.parentNode) {
				if (target === this.node) {
					return;
				}

				target = target.parentNode;
			}

			this.deactivate();
		};

		this.node.addEventListener('pointerenter', onPointerEnter);
		this.node.addEventListener('pointerleave', onPointerLeave);
		this.node.addEventListener('pointerup', onPointerUp);
		document.body.addEventListener('focus', onFocus, true);

		if (this.back) {
			this.back.addEventListener('click', this.deactivate.bind(this));
		}

		if (this.link) {
			this.link.addEventListener('click', onClick);
		}
	}

	/**
	 * Toggle this dropdown menu state
	 */
	toggle() {
		this.active ? this.deactivate() : this.activate();
	}

	/**
	 * Activate this dropdown menu state
	 */
	activate() {
		if (!this.active) {
			this.active = true;
			this.controller.deactivate(this);
			this.node.classList.add(this.controller.parentActiveClass);
			this.node.dispatchEvent(new Event( 'masthead:activate', { bubbles: true } ) );
		}
	}

	/**
	 * Deactivate this dropdown menu state
	 */
	deactivate() {
		if (this.active) {
			this.active = false;
			this.node.classList.remove(this.controller.parentActiveClass);
			this.node.dispatchEvent(new Event( 'masthead:deactivate', { bubbles: true } ) );
		}
	}
}

class Masthead {
	constructor() {
		this.node = document.querySelector('.masthead');

		if (!this.node) {
			return;
		}

		// no mode by default (can be search|menu)
		this.mode = null;

		// what mouse/pen mode do we want to operate under - hover or click
		this.pointerMode = this.node.dataset.pointerMode || 'hover';

		// the delay used for hover-intent
		this.hoverDelay = 200;

		// the item active class
		this.parentActiveClass = 'masthead__item--active';

		// node references for interaction
		this.nav = this.node.querySelector('.masthead__nav');
		this.menuIcon = this.node.querySelector('.masthead__menu-icon');
		this.search = this.node.querySelector('.masthead__search');
		this.searchIcon = this.node.querySelector('.masthead__search-icon');
		this.closeIcon = this.node.querySelector('.masthead__close-icon');
		this.language = this.node.querySelector('.masthead__language');

		// at what media query is the mobile menu triggered - update this value to match whatever is used in _masthead.scss
		this.mobileMediaQuery = '(max-width: 48em)';

		// initialize menu items that are parents
		this.parents = Array.from(this.node.querySelectorAll('.masthead__item--depth-0.masthead__item--has-children')).map((node) => {
			return new MastheadParent(node, this);
		});

		// initialize the language parent class
		if (this.language) {
			this.languageParent = new MastheadParent(this.language, this);
			this.languageParent.node.addEventListener( 'masthead:activate', () => this.node.classList.add( 'masthead--mode-language' ) );
			this.languageParent.node.addEventListener( 'masthead:deactivate', () => this.node.classList.remove( 'masthead--mode-language' ) );
		}

		// headroom sticky hide configuration - @see https://www.npmjs.com/package/headroom.js
		this.headroom = new Headroom(this.node, {
			tolerance: {
				up: 7,
				down: 5,
			},
			classes: {
				initial: 'masthead',
				pinned: 'masthead--pinned',
				unpinned: 'masthead--unpinned',
				top: 'masthead--top',
				notTop: 'masthead--not-top',
				bottom: 'masthead--bottom',
				notBottom: 'masthead--not-bottom',
				frozen: 'masthead--frozen',
			},
		});
		this.headroom.init();
		this.headroom.update(this.headroom);

		// deactivate the mode of the menu on escape key
		const onKeyUp = (e) => {
			switch (e.key) {
				case 'Escape':
					if (this.mode) {
						this.setMode('');
					}
					break;
			}
		};

		// deactivate mode when clicking outside of the masthead
		const onClick = (e) => {
			let target = e.target;

			while (target.parentNode) {
				if (target === this.nav) {
					return;
				}

				target = target.parentNode;
			}

			this.deactivate();
		};

		disable(null, this.search);

		this.node.addEventListener('keyup', onKeyUp);

		if ( this.searchIcon ) {
			this.searchIcon.addEventListener('click', (e) => {
				e.stopPropagation();
				this.toggleMode('search');
			});
		}

		if ( this.menuIcon ) {
			this.menuIcon.addEventListener('click', (e) => {
				e.stopPropagation();
				this.toggleMode('menu');
			});
		}

		if ( this.closeIcon ) {
			this.closeIcon.addEventListener('click', (e) => {
				e.stopPropagation();
				this.setMode();
			});
		}

		document.body.addEventListener('click', onClick);
	}

	/**
	 * Deactivate all dropdown menus (optionally exclude one menu)
	 *
	 * @param {MastheadParent} exclude
	 */
	deactivate(exclude) {
		this.parents.forEach((parent) => {
			if (!exclude || parent !== exclude) {
				parent.deactivate();
			}
		});

		if (!window.matchMedia(this.mobileMediaQuery).matches) {
			this.setMode('');
		}
	}

	/**
	 * Set the interactive mode of the masthead
	 *
	 * @param {string} mode search|menu
	 * @returns void
	 */
	setMode(mode = '') {
		if (this.mode && mode !== this.mode) {
			const lastMode = this.mode;

			if ('search' === lastMode) {
				disable(null, this.search);

				const onTransitionEnd = () => {
					this.search.hidden = true;
					this.search.removeEventListener('transitionend', onTransitionEnd);

					if (!this.node.classList.contains('masthead--search-overlay')) {
						this.nav.classList.remove('masthead__nav--search');
					}
				};

				this.search.addEventListener('transitionend', onTransitionEnd);
			}

			this.node.classList.remove(`masthead--mode-${lastMode}`);
			document.documentElement.classList.remove('has-masthead-mode', `has-masthead--mode-${lastMode}`);
		}

		this.mode = mode;

		if (mode) {
			if ('search' === this.mode) {
				enable(null, this.search);
				this.search.removeAttribute('hidden');
				const input = this.search.querySelector('input');

				if (input) {
					input.focus();
				}

				if (!this.node.classList.contains('masthead--search-overlay')) {
					this.nav.classList.add('masthead__nav--search');
				}
			}

			setTimeout(() => {
				this.node.classList.add(`masthead--mode-${this.mode}`);
				document.documentElement.classList.add('has-masthead-mode', `has-masthead--mode-${mode}`);
			}, 10);
		}

		this.node.dispatchEvent(new Event('masthead:mode', { detail: this }));
	}

	/**
	 * Toggle the interactive mode of the masthead
	 *
	 * @param {string} mode search|menu
	 * @returns void
	 */
	toggleMode(mode) {
		this.setMode(this.mode === mode ? '' : mode);
	}

	/**
	 * Gets the mode of the pointerType of how the dropdowns should react to pointer events
	 *
	 * - mobile view should always be click based even with mouse/pen pointer events
	 * - update the breakpoint
	 *
	 * @returns string hover|click
	 */
	getPointerMode() {
		if ('hover' === this.pointerMode && window.matchMedia(this.mobileMediaQuery).matches) {
			return 'click';
		}

		return this.pointerMode;
	}
}

export default new Masthead();
