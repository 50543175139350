/**
 * Array of selectors that are tab index capable
 */
export const tabCapable = [
	'select',
	'input',
	'textarea',
	'button',
	'a',
	'iframe',
	'object',
	'embed',
	'*[contenteditable]',
	'*[tabindex]',
];

/**
 * Disable nodes tabIndex while saving the previous tabIndex value in node.dataset
 *
 * @param {string} exclude query selector to filter disabling items
 * @param {HTMLElement} scope Defaults to document
 * @returns {array}
 */
export function disable( exclude = null, scope = document ) {
	scope = scope || document;

	let nodes = Array.from( scope.querySelectorAll( tabCapable.join( ', ' ) ) );

	if ( exclude ) {
		nodes = nodes.filter( node => ! node.matches( exclude ) && ! node.closest( exclude ) );
	}

	nodes.forEach( node => {
		if ( node.tabIndex && node.tabIndex !== '-1' ) {
			node.dataset.tabIndex = node.tabIndex;
		}

		node.tabIndex = '-1'
	} );

	return nodes;
}

/**
 * Enable nodes tabIndex by restoring a previously saved tabIndex or removing it entirely
 *
 * @param {string} exclude query selector to filter disabling items
 * @param {HTMLElement} scope Defaults to document
 * @returns {array}
 */
export function enable( exclude = null, scope = document ) {
	let nodes = Array.from( scope.querySelectorAll( tabCapable.join( ', ' ) ) );

	if ( exclude ) {
		nodes = nodes.filter( node => ! node.matches( exclude ) && ! node.closest( exclude ) );
	}

	nodes.forEach( node => {
		if ( node.dataset.tabIndex ) {
			node.tabIndex = node.dataset.tabIndex;
		} else {
			node.removeAttribute( 'tabIndex' );
		}
	} );

	return nodes;
}

export default {
	tabCapable,
	enable,
	disable,
}
