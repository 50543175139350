/**
 * IE11 Fix to convert object-fit: cover to background-size: cover
 *
 * @param {string|NodeList|Array} nodes
 * @param {string} img the img selector
 * @param {string} position the background position to use
 */
export default function objectFitCover( nodes = '.object-fit-cover', img = 'img', position = 'center' ) {
	if ( typeof nodes === 'string' ) {
		nodes = Array.from( document.querySelectorAll( nodes ) );
	}

	if ( ! nodes.length ) {
		return;
	}

	for ( let i = 0, nodesLength = nodes.length; i < nodesLength; i++ ) {
		let imgNode = nodes[i].querySelector( img );

		if ( ! imgNode ) {
			continue;
		}

		nodes[i].style.backgroundPosition = position;
		nodes[i].style.backgroundSize = 'cover';
		nodes[i].style.backgroundImage = `url(${imgNode.currentSrc || imgNode.src})`;
		imgNode.style.display = 'none';
	}
}

if ( document.readyState.match(/complete|loaded/) ) {
	objectFitCover();
} else {
	document.addEventListener('DOMContentLoaded', objectFitCover);
}
