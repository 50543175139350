class FocusWithin {
	constructor(node, className) {
		this.node = node;
		this.className = className;
		this.focused = false;

		// add events with capturing cause focus/blur don't bubble
		this.node.addEventListener('focus', this.focus.bind(this), true);
		this.node.addEventListener('blur', this.blur.bind(this), true);
	}

	toggle() {
		return this.focused ? this.blur() : this.focus();
	}

	focus() {
		this.focused = true;
		this.node.classList.add(this.className);

		this.node.dispatchEvent(new CustomEvent('site:focus-within:focus'));
	}

	blur() {
		this.focused = false;
		this.node.classList.remove(this.className);

		this.node.dispatchEvent(new CustomEvent('site:focus-within:blur'));
	}
}

export default function ( node, className = 'focus-within' ) {
	// pass dom element directly
	if ( node instanceof HTMLElement ) {
		return new FocusWithin(node, className);
	}

	// pass selector
	if ( typeof node === 'string' ) {
		node = document.querySelectorAll(node);
	}

	// convert to Array if not an array
	if (!Array.isArray(node)) {
		node = Array.from(node);
	}

	// convert to FocusWithin objects
	return node.map((n) => new FocusWithin(n, className));
}
